import { combineReducers } from 'redux';

import UserReducer from './user/user.reducer';
import RemittanceReducer from './remittance/remittance.reducer';
import AuditReducer from './audit/audit.reducer';
import UpdatesReducer from './updates/updates.reducer';
import VehicleReducer from './vehicle/vehicle.reducer';
import MicroscopeReducer from './microscope/microscope.reducer';
import WithdrawalReducer from './withdrawals/withdrawals.reducer';
import DisposalReducer from './disposals/disposals.reducer';
import CashbondLoanReducer from './cashbond-loan/cashbond-loans.reducer';

let reducers = {
  user: UserReducer,
  remittance: RemittanceReducer,
  audit: AuditReducer,
  updates: UpdatesReducer,
  vehicles: VehicleReducer,
  microscope: MicroscopeReducer,
  withdrawal: WithdrawalReducer,
  disposal: DisposalReducer,
  cashbondloan: CashbondLoanReducer
};

let rootReducer = combineReducers(reducers);

export default rootReducer;
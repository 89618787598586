import { h, Component } from "preact";
import { route } from "preact-router";
import { User } from "../../../../types/user.model";

import cx from "classnames";
import {
  ROLE_ADMIN_SALES,
  ROLE_SUPERVISOR,
  ROLE_VEHICLE_MANAGER,
} from "../../../../constants/role";
import getStore from "../../store";
import { LOGOUT } from "../../reducers/user/user.actions";

export interface SidenavProps {
  isSidenavVisible?: boolean;
  currentUser?: User;
  currentRoute?: string;
}

export interface SidenavState {
  isSidenavVisible?: boolean;
  currentUser?: User;
  currentRoute?: string;

  isUserManagerOrAbove?: boolean;
  isAuditorOrAbove?: boolean;

  hasSupervisorData?: boolean;
}

export class Sidenav extends Component<SidenavProps, SidenavState> {
  constructor(props: SidenavProps) {
    super(props);

    this.setState({
      isSidenavVisible: props.isSidenavVisible,
      currentUser: props.currentUser,
      currentRoute: location.pathname,
    });
  }

  componentWillReceiveProps(props: SidenavProps, b) {
    this.setState({
      isSidenavVisible: props.isSidenavVisible,
      currentUser: props.currentUser,
      currentRoute: props.currentRoute,
    });

    if (
      props.currentUser.role === "role_management" ||
      props.currentUser.role === "role_super_admin"
    ) {
      this.setState({
        isUserManagerOrAbove: true,
        isAuditorOrAbove: true,
      });
    } else if (props.currentUser.role === "role_auditor") {
      this.setState({
        isUserManagerOrAbove: false,
        isAuditorOrAbove: true,
      });
    } else {
      this.setState({
        isUserManagerOrAbove: false,
        isAuditorOrAbove: false,
      });

      if (
        props.currentUser.supervisor &&
        props.currentUser.supervisor.supervisorId
      ) {
        this.setState({
          hasSupervisorData: true,
        });
      }
    }
  }

  onLogout = () => {
    getStore().dispatch({
      type: LOGOUT,
    });
  };

  isRouteActive = (routeName: string) => {
    let [blank, first] = this.state.currentRoute.split("/");
    return first == routeName;
  };

  getRole(state: SidenavState) {
    let roleName: string;

    switch (state.currentUser.role) {
      case "role_supervisor":
        roleName = "Supervisor";
        break;

      case "role_auditor":
        roleName = "Auditor";
        break;

      case "role_management":
        roleName = "Management";
        break;

      case ROLE_ADMIN_SALES:
        roleName = "Admin Sales";
        break;
    }

    return roleName;
  }

  canAccessLoanAndCashbondPayments = (state: SidenavState) => {
    if (state.isUserManagerOrAbove) {
      return true;
    } else if (state.hasSupervisorData) {
      return true;
    } else if (this.props.currentUser.role === ROLE_ADMIN_SALES) {
      return true;
    }

    return false;
  };

  canAccessWithdrawals = (state: SidenavState) => {
    if (state.isUserManagerOrAbove) {
      return true;
    } else if (state.hasSupervisorData) {
      return true;
    } else if (this.props.currentUser.role === ROLE_ADMIN_SALES) {
      return true;
    }

    return false;
  };

  canAccessRemittance = (state: SidenavState) => {
    if (this.props.currentUser.role === ROLE_SUPERVISOR) {
      return true;
    } else if (state.isAuditorOrAbove) {
      return true;
    } else if (state.hasSupervisorData) {
      return true;
    } else if (this.props.currentUser.role === ROLE_ADMIN_SALES) {
      return false;
    } else if (state.currentUser.role === ROLE_VEHICLE_MANAGER) {
      return false;
    }

    return false;
  };

  render(props: SidenavProps, state: SidenavState) {
    return (
      <div>
        <div
          className={cx("anglo__sidenav-container", {
            visible: state.isSidenavVisible,
          })}
        >
          <div class="anglo__sidenav">
            <div class="anglo__sidenav__user">
              <div class="anglo__sidenav__user__img"></div>
              <p class="anglo__sidenav__user__name">
                {state.currentUser.firstName} {state.currentUser.lastName}
              </p>
              <p class="anglo__sidenav__user__type">{this.getRole(state)}</p>
            </div>
            <ul class="anglo__sidenav__routes-list">
              {/* <li class="anglo__sidenav__routes-list__item" 
                href="/account">
                <a>
                  <i class="fas fa-user"></i>
                  Account
                </a>
              </li> */}
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("dashboard"),
                })}
                onClick={() => {
                  route("/dashboard");
                }}
              >
                <a>
                  <i class="fas fa-chart-line"></i>
                  Dashboard
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("remittance"),
                  hidden: !this.canAccessRemittance(state),
                })}
                onClick={() => {
                  route("/remittance");
                }}
              >
                <a>
                  <i class="fas fa-money-bill-wave"></i>
                  Remittances
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("cashbond-loan"),
                })}
                onClick={() => {
                  route("/cashbond-loan");
                }}
              >
                <a>
                  <i class="fas fa-money-check-alt"></i>
                  Cashbonds/Loans
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("loan-payments"),
                  hidden: !state.isAuditorOrAbove,
                })}
                onClick={() => {
                  route("/loan-payments");
                }}
              >
                <a>
                  <i class="fa fa-file" aria-hidden="true"></i>
                  Loan Payments
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("cashbond-payments"),
                  // hidden: !state.isUserManagerOrAbove
                })}
                onClick={() => {
                  route("/cashbond-payments");
                }}
              >
                <a>
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  Cashbond Payments
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("withdrawal"),
                  hidden: !this.canAccessWithdrawals(state),
                })}
                onClick={() => {
                  route("/withdrawal");
                }}
              >
                <a>
                  <i class="fas fa-dolly-flatbed"></i>
                  Withdrawals
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("disposal"),
                  hidden: !this.canAccessWithdrawals(state),
                })}
                onClick={() => {
                  route("/disposal");
                }}
              >
                <a>
                  <i class="fas fa-people-carry"></i>
                  Disposals
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("microscope"),
                  hidden: !state.isUserManagerOrAbove,
                })}
                onClick={() => {
                  route("/microscope");
                }}
              >
                <a>
                  <i class="fas fa-chart-line"></i>
                  Remittance Microscope
                </a>
              </li>

              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("updates"),
                  hidden:
                    state.isUserManagerOrAbove ||
                    state.isAuditorOrAbove ||
                    state.currentUser.role === ROLE_VEHICLE_MANAGER ||
                    state.currentUser.role === ROLE_ADMIN_SALES,
                })}
                onClick={() => {
                  route("/updates");
                }}
              >
                <a>
                  <i class="fas fa-bell"></i>
                  Updates
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("user-management"),
                  hidden: !state.isUserManagerOrAbove,
                })}
                onClick={() => {
                  route("/user-management");
                }}
              >
                <a>
                  <i class="fas fa-users-cog"></i>
                  User Management
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("user-management"),
                  hidden: !state.isUserManagerOrAbove,
                })}
                onClick={() => {
                  route("/employees");
                }}
              >
                <a>
                  <i class="fas fa-male"></i>
                  Employee Management
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("audits"),
                  hidden: !state.isAuditorOrAbove,
                })}
                onClick={() => {
                  route("/audits");
                }}
              >
                <a>
                  <i class="fas fa-file-invoice-dollar"></i>
                  Auditing
                  {state.currentUser.editRequests.length > 0 ? (
                    <span class="anglo__sidenav__routes-list__item__notif">
                      {state.currentUser.editRequests.length}
                    </span>
                  ) : (
                    ""
                  )}
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("history"),
                  hidden: !state.isUserManagerOrAbove,
                })}
                onClick={() => {
                  route("/history");
                }}
              >
                <a>
                  <i class="fas fa-book"></i>
                  History Logs
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item", {
                  active: this.isRouteActive("vehicles"),
                  hidden: state.currentUser.role === "role_auditor",
                })}
                onClick={() => {
                  route("/vehicles");
                }}
              >
                <a>
                  <i class="fas fa-truck"></i>
                  Vehicles
                </a>
              </li>
              <li
                className={cx("anglo__sidenav__routes-list__item d-md-none")}
                onClick={() => {
                  this.onLogout();
                }}
              >
                <a>Logout</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="anglo__content__sidenav-padding d-none d-xl-block">
          {/* Using Takumi's style for keeping floating objects
              in a "relative" sense to others */}
        </div>
      </div>
    );
  }
}

import { Epic, combineEpics, ofType } from 'redux-observable';

import UserEffects from './user/user.effects';
import RemittanceEffects from './remittance/remittance.effects';
import AuditEffects from './audit/audit.effects';
import UpdateEffects from './updates/updates.effects';
import VehicleEffects from './vehicle/vehicle.effects';
import MicroscopeEffects from './microscope/microscope.effects';
import WithdrawalEffects from './withdrawals/withdrawals.effects';
import DisposalEffects from './disposals/disposals.effects';
import CashbondLoans from './cashbond-loan/cashbond-loans.effects';

export let appEffects: Epic[] = [];

appEffects = appEffects.concat(UserEffects);
appEffects = appEffects.concat(RemittanceEffects);
appEffects = appEffects.concat(AuditEffects);
appEffects = appEffects.concat(UpdateEffects);
appEffects = appEffects.concat(VehicleEffects);
appEffects = appEffects.concat(MicroscopeEffects);
appEffects = appEffects.concat(WithdrawalEffects);
appEffects = appEffects.concat(DisposalEffects);
appEffects = appEffects.concat(CashbondLoans);

export const rootEpic = combineEpics(...appEffects);

// ------- THE SECTION BELOW IS FOR TESTING STORE --------

import * as UserActions from './user/user.actions';
import getStore from '../store';

setTimeout(() => {
  const action = {
    type: UserActions.GET_CURRENT_USER
  }

  getStore().dispatch(action);
}, 200);
import { h, Component } from 'preact';


export interface ModalProps {
  close?: Function;
  containerId?: string;
  isOutsideClickClose?: boolean;
}

export abstract class Modal<P, S> extends Component<P, S> {
  refNode: any;

  componentDidMount() {
    
    if (this.props['isOutsideClickClose'] && this.props['containerId']) {
      let container = document.getElementById(this.props['containerId']);
      container.addEventListener('mousedown', this.onOutsideClick, false);
    }
    
  }

  onOutsideClick = (ev) => {
    const target = ev.target;

    if (target !== this.refNode) {
      // The reason for doing this instead of doing an outright this.props['close']()
      // is because we might need the close function to return something.
      // this help wrap aroud and the close function is called right;
      this.doModalClose();
    }
  }
  
  abstract doModalClose();
}